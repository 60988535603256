import { REGISTER_CUSTOMER, ERROR_CUSTOMER, CLEAR_ERROR_CUSTOMER, LOGOUT_CUSTOMER, RELOAD_CUSTOMER } from '../type';

const initialState: Customers = {
  access_token: '',
  adress: null,
  country: null,
  city: null,
  email: '',
  name: '',
  id: null,
  error: null,
  phone: null,
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case REGISTER_CUSTOMER:
      return {
        ...state,
        access_token: action.payload.access_token,
        adress: action.payload.customer?.adress,
        country: action.payload.customer?.country,
        city: action.payload.customer?.city,
        email: action.payload.customer.email,
        name: action.payload.customer.name,
        phone: action.payload.customer?.phone,
        id: action.payload.customer.id
      };
    case RELOAD_CUSTOMER:
      return {
        ...state,
        adress: action.payload.adress,
        country: action.payload.country,
        city: action.payload.city,
        email: action.payload.email,
        name: action.payload.name,
        phone: action.payload.phone,
        id: action.payload.id
      };
    case ERROR_CUSTOMER:
      return {
        ...state,
        error: action.payload
      };
    case CLEAR_ERROR_CUSTOMER:
      return {
        ...state,
        error: null
      };
    case LOGOUT_CUSTOMER:
      return {
        ...state,
        access_token: '',
        email: '',
        name: '',
        phone: null,
        adress: null,
        city: null, 
        id: null,
        error: null
      };
    default:
      return state;
  }
};
