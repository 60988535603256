import React from 'react';
import Lottie from 'lottie-react';
import Lazy from '../assets/lottie/oader.json';

const styles: React.CSSProperties = {
  textAlign: 'center',
  marginTop: 70,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: 300,
  height: 300,
  margin: 'auto',
  padding: 15
};

const LazyLoading = () => {
  return (
    <React.Fragment>
      <div style={styles}>
        <Lottie
          animationData={Lazy}
          loop
          width={300}
          height={300}
        />
      </div>
    </React.Fragment>
  );
};

export default LazyLoading;