import { GET_PRODUCT, ADD_PRODUCT, ERROR_PRODUCT, CLEAR_ERROR, HYDRATE_PRODUCT, LOGOUT } from '../type';

interface MyProductState {
  products: any[]
  productSelected: any[]
  category: []
  errorProduct: string | null
  successProduct: string | null
}

const initialState: MyProductState = {
  products: [],
  productSelected: [],
  category: [],
  errorProduct: '',
  successProduct: ''
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case GET_PRODUCT:
      return {
        ...state,
        products: action.paylaod
      };
    case ADD_PRODUCT:
      if (state.productSelected.includes(action.payload)) {
        return {
          ...state,
          productSelected: state.productSelected.filter((id: any) => id !== action.payload)
        };
      } else {
        return {
          ...state,
          productSelected: [...state.productSelected, action.payload]
        };
      }
    case HYDRATE_PRODUCT:
      return {
        ...state,
        productSelected: []
      };
    case ERROR_PRODUCT:
      return {
        ...state,
        errorProduct: action.payload
      };
    case CLEAR_ERROR:
      return {
        ...state,
        errorProduct: null
      };
    case LOGOUT:
      return { ...state, productSelected: [], products: [], category: [] };
    default:
      return state;
  }
};
