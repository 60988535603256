import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import axios from 'axios';
import { HelmetProvider } from 'react-helmet-async';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ErrorBoundary from './pages/errors/ErrorBundary';
import { Provider } from 'react-redux';
import { store } from './store/store';

axios.defaults.baseURL = `${process.env.REACT_APP_API}`;
axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('token')}`;
axios.defaults.headers.post['Content-Type'] = 'application/x-form-urlencoded';
axios.defaults.headers.post.Accept = 'application/json';
axios.defaults.headers.post['Content-Type'] = 'multipart/form-data';



const root = ReactDOM.createRoot(
  document.getElementById('ukuaji') as HTMLElement
);
root.render(
  <ErrorBoundary>
    <React.StrictMode>
      <HelmetProvider>
        <Provider store={store}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </Provider>
      </HelmetProvider>
    </React.StrictMode>
  </ErrorBoundary>
);

reportWebVitals();
