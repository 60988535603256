import {
  GET_CONFIG,
  GET_PERCENTAGE,
  GET_CONFIG_STORE,
  LOGOUT,
  GET_STORE_PUBLIC,
  LOADING,
  TOGGLE_OPEN,
  GET_BRANDS,
  GET_CATEGORIES
} from '../type';

interface InitialConfig {
  paymentMethod: any[]
  percentage: number
  shipping: number
  percentageAdmin: number
  limitCategory: number
  limitProduct: number
  store: ConfigStore
  stores: StorePublic
  loading: boolean
  opened: boolean
  categories: any[]
  brands: any[]
}

const initialState: InitialConfig = {
  paymentMethod: [],
  percentage: 0,
  percentageAdmin: 0,
  shipping: 0,
  limitProduct: 1,
  limitCategory: 3,
  store: {
    name_shop: '',
    uuid: '',
    metadata: '',
    slug: '',
    is_whatsapp: 0,
    code_pin: null,
    icone: ''
  },
  stores: {
    name_shop: '',
    uuid: '',
    metadata: '',
    slug: '',
    icone: '',
  },
  loading: false,
  opened: false,
  categories: [],
  brands: []
};

const configReducer =  (state = initialState, action: any) => {
  switch (action.type) {
    case LOADING:
      return { ...state, loading: action.payload };
    case GET_CONFIG:
      return {
        ...state,
        paymentMethod: action.payload
      };
    case GET_PERCENTAGE:
      return {
        ...state,
        percentage: action.payload.percentage_comm,
        percentageAdmin: action.payload.percentage_admin,
        limitCategory: action.payload.limit_category,
        limitProduct: action.payload.limit_product,
        shipping: action.payload.amount_shipping
      };
    case GET_CONFIG_STORE:
      return { ...state, store: action.payload };
    case GET_STORE_PUBLIC:
      return { ...state, stores: action.payload };
    case GET_BRANDS:
      return { ...state, brands: action.payload };
    case GET_CATEGORIES:
      return { ...state, categories: action.payload };
    case TOGGLE_OPEN:
      return { ...state, opened: action.payload };
    case LOGOUT:
      return { ...state, store: null, paymentMethod: [], percentage: 0 };
    default:
      return state;
  }
};

export default configReducer;