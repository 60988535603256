import React from 'react';
import { Routes, Route } from 'react-router-dom';
import LazyLoading from '../components/LazyLoading';

import FourByFour from '../pages/errors/FourByFour';

// lazy loading
const Landing = React.lazy(() => import('../pages/landing'));

const Index = () => {
  return (
    <React.Fragment>
      <Routes>
        <Route path="/"
          element={
            <React.Suspense fallback={<LazyLoading />} >
              <Landing />
            </React.Suspense>
          }
        />
        <Route path="*" element={<FourByFour />} />
      </Routes>
    </React.Fragment>
  );
};

export default Index;
