import { ADD_CART, REMOVE_CART, UPDATE_CART, GET_CART, EMPTY_CART } from '../type';

interface Cart {
  cart: any[]
}

const initialState: Cart = {
  cart: []
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case GET_CART: {
      return {
        ...state,
        cart: action.payload
      };
    }
    case ADD_CART: {
      if (state.cart.includes(action.payload.product_uuid) && state.cart.includes(action.payload.color_buy) ) {
        return {
          ...state,
          cart: state.cart.filter((item: any) => item.product_uuid !== action.payload.product_uuid)
        };
      } else {
        return {
          ...state,
          cart: [...state.cart, action.payload]
        };
      }
    }
    case UPDATE_CART: {
      const cartIndex = state.cart.findIndex((item: any) => item.product_uuid === action.payload.product_uuid);
      state.cart.filter((item) => item.product_uuid !== cartIndex);

      return {
        ...state,
        cart: [...state.cart, action.payload]
      };
    }
    case REMOVE_CART: {
      const newCarts = state.cart.filter((item: any) => {
        return item.product_uuid !== action.payload.product_uuid && item.color_buy !== action.payload.color_buy
      });
      return {
        ...state,
        cart: newCarts
      };
    }
    case EMPTY_CART:
      return { ...state, cart: [] };
    default:
      return state;
  }
};
